/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Prevent overscroll bounce */
html, body {
    overscroll-behavior: none;
}

/* Add this to your global CSS */
@supports (padding: env(safe-area-inset-top)) {
    body {
        padding-top: env(safe-area-inset-top);
    }
}

body {
    overflow-x: hidden; /* Prevent horizontal scrolling on body */
}


.carousel-item {
    min-width: 300px; /* Set minimum width for each city card */
    margin: 0 10px; /* Add margin between items */
}

.animate-slide {
    animation: slide 20s linear infinite; /* Adjust speed as necessary */
}

nav {
    background-color: white; /* Or any other color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

footer {
    background-color: #2d3748; /* Gray 800 */
}

/* src/index.css */
nav {
    background-color: white; /* Default navbar color */
    transition: background-color 0.3s ease; /* Smooth transition */
}

/* Add these in your Tailwind config or custom CSS for responsiveness */
@media (min-width: 640px) {
    .grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  @media (min-width: 768px) {
    .grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media (min-width: 1024px) {
    .grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  @media (min-width: 1280px) {
    .grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  
  .carousel-container {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory; /* Enable snap scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
    padding: 1rem 0;
}

.carousel-card {
    scroll-snap-align: center;
    flex: 0 0 auto;
    width: 250px; /* Ensure this matches CityCard's width */
    height: 300px; /* Ensure this matches CityCard's height */
    margin-right: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel-card:hover {
    transform: scale(1.05); /* Enlarge card on hover */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3); /* Increase shadow on hover for depth */
}

.carousel-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

@media (min-width: 768px) {
    .carousel-card {
        width: 300px; /* Larger card size for larger screens */
        height: 350px;
    }
}

